.b-large-arrow {
	color: #fff;
	font-family: $font-black;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 1px;
	min-height: 100px;
	display: flex;
    align-items: center;

	&:after{
		content: '';
		display: inline-block;
		width: 100px;
		height: 100px;
		margin-left: 45px;
		border-radius: 50%;
		border: 3px solid $light-blue;
		background: url(../images/icons/arrow-right.png) no-repeat;
		background-size: 36px;
		background-position: center;
		transition: 0.25s all ease;
	}

	&:hover,
	&:focus {
		color: #fff;
		text-decoration: none;

		&:after{
			background-color: #000;
			background-position: 35px;
		}
	}

	@include mq(small) {
		&:after {
			width: 70px;
			height: 70px;
			margin-left: 20px;
		}
	}
}

.button {
	color: $dark-blue;
	border: 1px solid $dark-blue;
	font-size: 15px;
	font-family: $font-black;
	letter-spacing: 2px;
	text-transform: uppercase;
	padding: 15px 30px;
	display: inline-block;

	&.lighter-b {
		color: #276fed;
		border-color: #276fed;
	}

	&:hover,
	&:focus {
		background: $dark-blue;
		color: #fff;
		text-decoration: none;

		&.lighter-b {
			background: #276fed;
		}
	}
}

.button--white {
	@extend .button;
	color: #fff;
	border: 1px solid #fff;

	&:hover,
	&:focus {
		background: #fff;
		color: $color-link;
	}
}
