input[type="text"],
input[type="email"],
textarea {
	border: 2px solid #cdcdcd;
	padding: 15px 25px;
	width: 100%;
	margin-bottom: 20px;
	outline: none;
	transition: 0.2s ease all;

	&:hover,
	&:focus {
		border: 2px solid $color-link; 
	}
}

textarea {
	min-height: 200px;
}

button[type="submit"] {
	width: 100%;
	border: none;
	outline: none;
	background: $color-link;
	padding: 15px 0;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	font-family: $font-black;
	font-size: 15px;
	transition: 0.2s ease all;

	&:hover,
	&:focus {
		background: #143a7b;
	}
}

div.wpcf7-validation-errors {
    border: none;
    margin: 0;
    padding: 0;
    color: red;
}

div.wpcf7-mail-sent-ok {
	border: none;
    margin: 0;
    padding: 0;
    color: $color-link;
} 