.project-title {
	font-size: 45px;
	font-family: $font-black;
	color: #322f31;
	text-transform: uppercase;
	margin-bottom: 0;
	text-align: center;

	&:before {
		content: '';
		display: block;
		width: 60px;
		height: 6px;
		background: $color-link;
		margin: 0 auto 30px auto;
	}

	@include mq(medium) {
		font-size: 28px;
	}
}

.project-description {
	font-size: 36px;
	color: $color-link;
	margin-top: 0;
	text-align: center;

	@include mq(medium) {
		font-size: 20px;
	}
}

.project-wrapper {
	padding: 50px 120px;

	@include mq(medium) {
		padding: 50px 20px;
	}
}

.project-single-slide {
	width: 25%;
	padding-top: 7%;
	background-position: center center;
	background-size: cover;

	img {
		display: none;
	}
}