/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
        width: 100%;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-next,
.slick-prev {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #276fed;
    background: url(../images/icons/arrow-right.png) no-repeat;
    background-size: 36px;
    background-position: center;
    position: absolute; 
    text-indent: -9999px;
    top: 50%;
    margin-top: -50px;
    transition: 0.25s all ease;
    z-index: 2;
    outline: none;

    &:hover,
    &:focus {
        background-color: #000;
    }
}

.slick-next {
    right: -22px;
}

.slick-prev {
    left: -22px;
    background-image: url(../images/icons/arrow-left.png);
}

.slick-dots {
    position: absolute;
    bottom: 50px;
    left: 50%;
    padding: 0px;
    transform: translate(-50%, 0px);
    
     @include mq(large) {
        bottom: 25px;
    }

    @include mq(medium) {
        bottom: 0px;
    }

    li {
        display: inline-block;
        list-style: none;
        padding: 10px;
    }

    button {
        font-size: 0px;
        display: block;
        height: 16px;
        width: 12px;
        border-radius: 12px;
        border: 2px solid white;
        background: transparent;
    }

    li.slick-active button {
        background: white;
    }
}







