.flex {
	display: flex;
	flex-wrap: wrap;
}

.vertical-align {
	align-items: center;
}

.align-right {
	text-align: right;
}

.clearfix {
	overflow: hidden;
}

.horizontal-align {
	text-align: center;
}

.fully-centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

.absolute {
	position: absolute;
}

.relative {
	position: relative;
}

.inline-block {
	display: inline-block;
}

.text-white {
	color: #fff;
}

.border-bottom {
	border-bottom: 1px solid #000;
}

.box-left {
	padding: 60px 60px 60px 110px;

	@include mq(large) {
		padding: 30px;
	}
}

.box-left--mini {
	padding: 0 0 0 40px;

	@include mq(medium) {
		padding: 30px;
	}
}

.padded-block {
	padding: 0 40px;

	@include mq(medium) {
		padding: 0;
	}
}

.white-bg {
	background: #fff;
}

.stick-right {
	position: absolute;
	right: -20px;

	@include mq(medium) {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	@include mq(small) {
		position: static;
		transform: none;
	}
}

.cta {
	padding: 30px 0;
	background: url(../images/gradient.jpg) no-repeat center;
	background-size: cover;
}

.short-banner-text {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
}

.bottom-left-logo {
	&:before {
		content: '';
		width: 50%;
    	padding-top: 24%;
		background: url(../images/slideshow-bg.png) no-repeat;
		background-size: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 2;
	}

	@include mq(medium) {
		&:before {
			display: none;
		}
	}
}

.bottom-margin {
	margin-bottom: 70px;
}

.google-map {
	height: 450px;
}

.spacing-left {
	margin-left: 40px;
}

.grey-text {
	color: $color-text-lighter;
}