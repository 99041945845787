.realisations-block {

	@include mq(large) {
		.l-4, .l-8 {
			width: 100%;
		}
	}
}

.mega-icon {

	h2 {
		font-size: 30px;
		font-family: $font-black;
		color: #fff;
		text-transform: uppercase;
		margin: 0;
	}

	p {
		font-size: 26px;
		margin: 0;
	}


	@include mq(large) {
		h2 {
			font-size: 24px;
		}

		p {
			font-size: 18px;
		}
	}
}

.mega-icon {
	position: relative;
	display: flex;
	align-items: center;

	&:before {
		content: '';
		display: block;
		position: absolute;
		background-position: center;
		background-repeat: no-repeat;
		border: 2px solid $color-link;
		border-radius: 50%;
		top: -45px;
		left: -50px;
		width: 140px;
		height: 140px;
	}
}

.spacing-left {
	margin-left: 40px;

	@include mq(large) {
		margin-left: 130px;
	}
}

.mega-icon.i-bishop {
	&:before {
		background-image: url(../images/icons/bishop.png);
		background-size: 50px;
	}	
}

.mega-icon.i-head {
	&:before {
		background-image: url(../images/icons/head.png);
		background-size: 70px;
	}
}

.mega-icon.i-brain {
	&:before {
		background-image: url(../images/icons/brain.png);
		background-size: 80px;
	}
}

.mega-icon.i-sound {
	&:before {
		background-image: url(../images/icons/sound.png);
		background-size: 80px;
	}
}
