.bg-qui-sommes-nous {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;

	@include mq(medium) {
		min-height: 200px;
		padding: 40px 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.grey-tile {
	background: $grey-black;
	padding: 60px 20px;

	h3 {
		margin-top: 20px;
		margin-bottom: 0;
	}
}

.grey-tile__link {
	font-family: $font-black;
	font-size: 36px;
	color: #fff;
	text-transform: uppercase;
    margin-left: calc( 50% - 130px );
    display: block;
    position: relative;

	div {
		position: relative;
		z-index: 2;
	}

	.top-part {
		position: relative;
		z-index: 2;

		&:after {
			content: '';
			display: inline-block;
			width: 36px;
			height: 20px;
			background: url(../images/icons/arrow-right.png) no-repeat;
			background-size: 36px;
			opacity: 0;
			transform: translateX(0px);
			transition: 0.25s ease all;
		}
	}

	.bottom-part {
		display: block;
		color: $color-text-lighter;
		font-family: $font-light;
		text-transform: none;
		font-size: 20px;
		margin-left: 20px;
		position: relative;
		z-index: 2;
	}

	&:before {
		content: '';
		display: inline-block;
		width: 130px;
		height: 130px;
		margin-right: 0px;
		border-radius: 50%;
		border: 3px solid $light-blue;
		background-position: center;
		background-repeat: no-repeat;
		transition: 0.25s all ease;
		position: absolute;
		left: -110px;
    	top: -45px;
		z-index: 1;
	}

	@include mq(large) {
		font-size: 24px;
		line-height: 32px;
		margin-left: calc( 50% - 50px );
	}

	@include mq(small) {
		margin-left: 100px;

		&:before {
			left: -110px;
			transform: scale(0.5);
		}

		.top-part {
			&:after {
				display: none;
			}
		}
	}
}

.grey-tile--button {
	@extend .grey-tile;
	display: block;
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
	padding: 100px 20px 60px 20px;

	&:hover,
	&:focus {
		text-decoration: none;
		background: #000;
	
		.top-part:after {
			opacity: 1;
			transform: translateX(40px);
		}
	}

	&:odd {
		border-right: none;
	}
}

.i-bishop {
	&:before {
		background-image: url(../images/icons/bishop.png);
		background-size: 41px 75px;
	}	
}

.i-head {
	&:before {
		background-image: url(../images/icons/head.png);
		background-size: 61px 82px;
	}
}

.i-brain {
	&:before {
		background-image: url(../images/icons/brain.png);
		background-size: 63px 71px;
	}
}

.i-sound {
	&:before {
		background-image: url(../images/icons/sound.png);
		background-size: 72px 70px;
	}
}

.project-tiles {
	//background: url(../images/contact-1.jpg);
	background-size: cover;
	background-position: center center;
	padding-top: 25%;
	position: relative;

	&:hover,
	&:focus {

		.project-tiles__content {
			opacity: 1;
			pointer-events: auto;

			span:after {
				opacity: 1;
				transform: translateX(0px);
			}
		}
	}

	@include mq(large) {
		padding-top: 50%;
	}
}

.project-tiles--large {
	@extend .project-tiles;
	padding-top: 35%;

	@include mq(large) {
		padding-top: 50%;
	}
}

.project-tiles__content {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background: rgba(38, 80, 154, 0.75);
	opacity: 0;
	pointer-events: none;
	transition: 0.25s all ease;

	span {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-size: 36px;
		font-family: $font-black;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
	
		&:after {
			content: '';
			display: block;
			margin: 10px auto;
			width: 36px;
			height: 20px;
			background: url(../images/icons/arrow-right-white.png) no-repeat;
			background-size: 100%;
			opacity: 0;
			transform: translateX(-40px);
			transition: 0.25s ease all;
			transition-delay: 0.1s;
		}
	}
}

.single-slide {
	position: relative;
}

.single-slide__text {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	.number {
		display: block;
	    position: absolute;
	    top: -50px;
	    left: -50px;
	    border: 3px solid $light-blue;
	    font-family: $font-light;
	    font-size: 60px;
	    line-height: 70px;
	    padding: 20px;
	    border-radius: 50%;
	    color: $light-blue;
	    z-index: 1;
	    width: 115px;
	    height: 115px;
	    text-align: center;
	    opacity: 0;
	    transform: translate(-100px, 0);
		transition: 0.25s all ease;

	    .slide-up &{
	    	opacity: 1;
			transform: translate(0px, 0);
	    }
	
		@include mq(large) {
		    top: -25px;
		    left: -45px;
		    font-size: 35px;
		    line-height: 40px;
		    padding: 12px;
		    border-radius: 50%;
		    width: 70px;
		    height: 70px;
		}

		@include mq(small) {
			display: none;
		}
	}

	.main {
		color: #fff;
		font-size: 110px;
		font-family: $font-black;
		text-transform: uppercase;
		display: block;
		position: relative;
		z-index: 2;
		opacity: 0;
	    transform: translate(-100px, 0);
		transition: 0.25s all ease;
		transition-delay: 0.1s;

		.arrow-banner {
			content: '';
			display: inline-block;
			vertical-align: middle;
			background: none;
			border: none;
			margin-left: 50px;
			margin-top: -30px;
			width: 36px;
			height: 20px;
			background: url(../images/icons/arrow-right-white.png) no-repeat;
			background-size: 100%;
			transition: 0.25s ease all;
			transition-delay: 0.1s;
			position: absolute;
			right: -60px;
			top: calc( 50% + 20px );
		}

		.slide-up &{
	    	opacity: 1;
			transform: translate(0px, 0);
	    }

	    @include mq(large) {
	    	font-size: 70px;
	    	.arrow-banner {
				margin-left: 20px;
	    	}
	    }

	    @include mq(small) {
			font-size: 40px;

			.arrow-banner {
				display: none;
			}
		}
	}

	.second {
		color: #fff;
		font-size: 24px;
		font-family: $font-light;
		float: right;
		margin-right: 50px;
		opacity: 0;
	    transform: translate(-100px, 0);
		transition: 0.25s all ease;
		transition-delay: 0.2s;

		
		&:before {
			content: '';
			width: 60px;
			height: 6px;
			margin-bottom: 10px;
			display: block;
			background: $color-link;
		}

		.slide-up &{
	    	opacity: 1;
			transform: translate(0px, 0);
	    }

	    @include mq(large) {
	    	font-size: 18px;
	    }
	
		@include mq(small) {
			font-size: 14px;
		}
	}

}

.slideshow-button-wrapper {
	position: absolute;
	bottom: 20px;
	left: 10px;
}

.js-slideshow-button {
	color: #fff;
	font-size: 14px;
	font-family: $font-light;
	text-transform: uppercase;
	margin: 0 10px;
	display: inline-block;
	background: $grey-black;
	padding: 10px 20px;

	&:hover,
	&:focus,
	&.active {
		color: #fff;
		background: #000;
		text-decoration: none;
	}
}

.banner {
	margin-bottom: 120px;

	@include mq(xsmall) {
		display: none;
	}
}

.skip-banner {
	position: absolute;
	bottom: -50px;
	left: calc( 50% - 50px );
	text-indent: -9999px;
	display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #276fed;
    background: url(../images/icons/arrow-right.png) no-repeat;
    background-size: 36px;
    background-position: center;
    transform: rotate(90deg);

    &:hover,
    &:focus {
    	background-color: #000;
    	background-position: 35px center;
    }

    @include mq(medium) {
    	display: none;
    }
}


#who-we-are {

	h3 {
		@extend .title-upper-bold;
	}
}
