/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	'xsmall': 		(max-width: 479px),
	'small': 		(max-width: 599px),
	'medium': 		(max-width: 767px),
	'large': 		(max-width: 1199px),
	'xxl': 			(max-width: 1499px),
	'tall': 		(min-height: 780px),
);


/* =======================================================================
Font-face
========================================================================== */
@font-face {
	font-family: 'brandon-light';
	src: url(../fonts/Brandon_light.otf) format('opentype');
}
@font-face {
	font-family: 'brandon-medium';
	src: url(../fonts/Brandon_med.otf) format('opentype');
}
@font-face {
	font-family: 'brandon-bold';
	src: url(../fonts/Brandon_bld.otf) format('opentype');
}
@font-face {
	font-family: 'brandon-black';
	src: url(../fonts/Brandon_blk.otf) format('opentype');
}


/* =======================================================================
Variables
========================================================================== */

// Fonts stacks
$font-light:             'brandon-light', 'Quicksand', sans-serif;
$font-medium:            'brandon-medium', 'Quicksand', sans-serif;
$font-bold:              'brandon-bold', 'Quicksand', sans-serif;
$font-black:             'brandon-black', 'Quicksand', sans-serif;
$font-size-base:          20px;
$font-size-mobile:		  16px;
$line-height-base:        1.4;

// Typography
$color-text:              #5d5d5d;
$color-text-lighter:	  #a0a0a0;
$color-link:              #26509a;
$color-placeholder: 	  $color-text;

// Colors
$dark-grey: 			  #323132;
$light-grey:			  #cdcdcd;
$grey-black:			  #322f31;
$light-grey: 			  #efefef;
$light-blue:			  #276fed;
$dark-blue:			  	  #2853a5;

