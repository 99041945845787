.title-over-border {
	font-family: $font-black;
	font-size: 45px;
	color: #000;
	text-transform: uppercase;
	margin-bottom: 30px;

	&:before {
		content: '';
		display: block;
		width: 60px;
		height: 6px;
		background: $color-link;
		margin: 0 0 20px 0;
	}

	@include mq(large) {
		font-size: 30px;
	}
}

.title-light-blue-under-border {
	font-family: $font-light;
	font-size: 36px;
	margin: 0.3em 0;
	color: $color-link;

	&:after {
		content: '';
		display: block;
		width: 300px;
		height: 2px;
		background: $light-grey;
		margin: 30px 0 0 0;
	}

	@include mq(large) {
		font-size: 22px;

		&:after {
			display: none;
		}
	}
}

.title-blue-small {
	font-family: $font-light;
	font-size: 24px;
	color: $color-link;
	text-transform: uppercase;

	@include mq(large) {
		font-size: 18px;
	}
}

.single-employee {
	display: block;
	background-size: cover;
	background-position: center;
	font-size: 24px;
	color: #000;
	font-family: $font-black;
	text-transform: uppercase;
	padding: 0px 15px 15px 0px;
}

.employee-title {
	display: inline;
	padding-right: 24px;
	cursor: pointer;
	background: url(../images/icons/arrow-right.png) no-repeat scroll calc(100% - 2px) 50% / 16px auto;
	transition: all 300ms;

	&:hover, &:focus {
		color: #666;
	}
}

.wrapper-employees {
	padding-top: 0px;
}

.employee-description {

	p {
		margin-top: 0px;
		font-size: 18px;
		padding-right: 80px;

		@include mq(medium) {
			padding-right: 0px;
		}
	}

	&.text-left {
		text-align: left;

		@include mq(medium) {
			text-align: center;
		}
	}
}

.wrapper-single-employee {
	position: relative;
	overflow: hidden;

	&.text-left {
		text-align: left;

		@include mq(medium) {
			text-align: center;
		}
	}

	span {
		font-size: 18px;
		text-transform: none;
		letter-spacing: 1px;
		display: block;
		margin: 5px 0;
		font-family: $font-medium;
		color: $color-text;
	}

	a {
		color: $color-link;
		
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	a.linked-in {
		font-size: 24px;
	}
	
}

.full-on-tablet {
	@include mq(large) {
		width: 100%;
	}
}

.single-combinaison {
	max-width: 150px;
	margin: 20px 40px 0 40px;
	display: inline-block;
	vertical-align: top;
	position: relative;

	.number {
		font-size: 28px;
		color: $light-blue;
		padding: 5px;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		border: 2px solid $light-blue;
		display: inline-block;
	}

	&:after {
		content: '';
		background: url(../images/icons/arrow-right.png) no-repeat;
		background-size: 36px;
		width: 36px;
		height: 22px;
		display: inline-block;
		margin-left: 15px;
		position: absolute;
		top: 40px;
		right: -60px;
	}

	&:last-of-type {
		&:after {
			display: none;
		}
	}

	@include mq(medium) {
		width: 100%;
		
		&:after {
			display: none;
		}
	}
}

.coordinates-block {
	padding-top: 120px;

	a {
		font-family: $font-black;
	}

	@include mq(medium) {
		padding-top: 0px;
	}
}

#description {

	h4 {
		@extend .title-blue-small;
	}
}





