html,
button,
input,
select,
textarea,
body {
	color: $color-text;
	font-family: $font-light;
}

body {
	// @include breakpoint-label;
	background: $light-grey;
	font-size: $font-size-base;
	line-height: $line-height-base;

	@include mq(large) {
		font-size: $font-size-mobile;
	}
}

b, strong {
	font-family: $font-black;
}

a {
	color: $color-link;
	text-decoration: none;
	transition: 0.25s all ease;
}

blockquote {
	p {
		font-family: $font-black;
		color: $color-link;
	}
}

img { vertical-align: middle; max-width: 100%; }

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

fieldset {
	border: 0;
	margin: 0; padding: 0;
}

textarea {
	resize: vertical;
}

// Placeholder color
::-webkit-input-placeholder { /* WebKit browsers */
	color: $color-placeholder;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: $color-placeholder;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: $color-placeholder;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
	color: $color-placeholder;
}

.copyright-bar {
	display:flex;
	flex-flow:row wrap;
	justify-content:center;
	align-items:center;
	margin:0 50px;
	text-align:center;

	@media screen and (max-width:599px) {
		flex-flow:column wrap;
	}

	.social {
		display:flex;
		flex-flow:row wrap;
		align-items:center;

		i {
			margin-left:15px;
			font-size:15px;
			color:#322f31;
			transition:all 300ms ease;

			@media screen and (max-width:599px) {
				margin:0px 15px;
			}

			&:hover {
				color:#276fed;
			}
		}
	}
}