.container {
	width: calc( 100% - 100px );
	margin: 0 auto;
	max-width: 1500px;
	padding: 0;

	@include mq(xxl) {
		margin: 0 50px;
	}

	@include mq(medium) {
		margin: 0 12px;
		width: calc( 100% - 24px );
	}
}

.container-text {
	overflow: hidden;
	clear: both;
	padding: 60px;

	ul {
		@extend .checked-list;
	}

	@include mq(medium) {
		padding: 40px 20px;
	}
}

.bg-white {
	background: #fff;
}

footer {
	padding: 40px 0;

	.inline-block {
		margin: 0 20px;
		color: $grey-black;
		font-size: 15px;
		font-family: $font-black;
	}
}

.footer-logo {
	width: 45px;

	@include mq(small) {
		display: block;
		margin: 10px auto;
	}
}

.page-banner {
	width: 100%;
	min-height: 600px;
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-bottom: 120px;
	padding: 0 12px;

	&.with-overlay {

		&:before {
			content: " ";
			width: 100%;
			height: 100%;
			top: 0px;
			bottom: 0px;
			left: 0px;
			right: 0px;
			background: rgba(0,0,0,0.35);
			z-index: 0;
			position: absolute;
		}

		.horizontal-align {
			z-index: 1;
			position: relative;
		}
	}

	h1 {
		max-width: 900px;
		margin: auto;
		color: #fff;
		font-size: 45px;
		font-family: $font-black;
		text-transform: uppercase;
		text-align: center;
		display: inline-block;

		&:before {
			content: '';
			display: block;
			width: 60px;
			height: 6px;
			background: $color-link;
			margin: 0 auto 30px auto;
		}
	}

	h2 {
		font-family: $font-black;
		text-transform: uppercase;
		text-align: center;
		display: block;
		margin-left: auto;
		margin-right: auto;
		color: #fff;
		font-size: 24px;
	}

	@include mq(large) {
		h1 {
			font-size: 28px;
		}
	}

	@include mq(medium) {
		min-height: auto;
		padding: 100px 12px;
		margin-bottom: 20px;
	}
}

/* =======================================================================
Grid
========================================================================== */

.l-12 {
	width: 100%;
}

.l-8 {
	width: 66.6666%;
	float: left;

	@include mq(medium) {
		width: 100%;
	}
}

.l-6 {
	width: 50%;
	float: left;

	@include mq(medium) {
		width: 100%;
	}
}

.l-5 {
	width: 41.66666%;
	float: left;

	@include mq(large) {
		width: 50%;
	}

	@include mq(small) {
		width: 100%;
	}
}

.l-4 {
	width: 33.33%;
	float: left;

	@include mq(large) {
		width: 50%;
	}

	@include mq(small) {
		width: 100%;
	}
}

.l-35 {
	width: 29.16666%;
	float: left;

	@include mq(large) {
		width: 50%;
	}

	@include mq(small) {
		width: 100%;
	}
}

.l-3 {
	width: 25%;
	float: left;

	@include mq(large) {
		width: 50%;
	}

	@include mq(small) {
		width: 100%;
	}
}