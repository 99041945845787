.main-nav {
	text-align: center;
	position: relative;

	ul {
		margin: 30px 0;
		padding: 0;
		list-style: none;
		display: inline-block;

		@include mq(large) {
			margin-top: 0;
		}
		@include mq(small) {
			max-height: 0;
			pointer-events: none;
			overflow: hidden;
			transition: 0.4s max-height ease;

			.mobile-on & {
				pointer-events: auto;
				max-height: 1000px;
				transition: 0.4s max-height cubic-bezier(1.000, 0.000, 1.000, 0.570);
			}
		}
	}
	
	li {
		display: inline-block;
		vertical-align: middle;
	}
	
	a {
		color: $dark-grey;
		font-family: $font-black;
		display: inline-block;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin: 0 20px;
		padding: 0 10px;

		&:after {
			content: '';
			display: block;
			height: 1px;
			width: 0px;
			margin: 5px 0 0 0;
			background: $color-link;
			transition: 0.25s all ease;
		}

		&:hover,
		&:focus {
			color: $color-link;
			text-decoration: none;

			&:after {
				margin: 5px 0 0 0;
				width: 100%;
			}
		}
	}

	.main-logo {
		a {
			width: 250px;
			display: inline-block;
		
			&:after {
				display: none;
			}
		}

		@include mq(large) {
			display: none;
		}			
	}

	.mobile-main-logo {
		display: none;
		width: 180px;
		margin: 30px auto 10px auto;

		@include mq(large) {
			display: block;
		}
	}

	.right-menu {
		margin: 0 0 0 100px;

		a {
			margin: 0 10px;
			padding: 0;

			&:after{
				display: none;
			}
		}

		@include mq(large) {
			margin: 0 0 0 30px;
		}

		@include mq(small) {
			margin: 0;

			a {
				width: auto;
			}
		}
	}

	@include mq(large) {
		a {
			margin: 0 10px;
		}
	}

	@include mq(small) {
		li {
			display: block;
		}
		a {
			margin: 0;
			padding: 10px 0;
			width: 100%;
		}
	}
}

.menu-left-part-container,
.menu-right-part-container {
	display: inline-block;
}	

.facebook-page {
	svg {
		width: 20px;
		height: 25px;
		display: inline-block;
		vertical-align: middle;
	}

	&:hover,
	&:focus {
		fill: $color-link;
	}
}

.lang-toggle {
	position: relative;
	display: inline-block;
	top: 3px;
}

.svg-sprite {
	position: absolute;
	pointer-events: none;
}

.mobile-menu-toggle{
	position: absolute;
	right: 15px;
	top: 20px;
	display: none;
	border:none;
	background:none;
	cursor: pointer; 
	padding: 10px 35px 16px 0px;
	transition: top 0.4s, transform 0.3s;
	z-index: 15;
	outline: none;
	
	span, span:before, span:after{
		cursor: pointer;
		border-radius: 1px;
		height: 3px;
		width: 30px;
		background: $color-text;
		position: absolute;
		display: block;
		content: '';
		transition: all 0s;
	}
	span{
		&:before{
			top: -10px; 
		}
		&:after{
			bottom: -10px;
		}
		// Mobile menu showing up
		.mobile-on &{
			background: transparent;
			&:before{
				top: 0;
				transform: rotate(45deg);
			}
			&:after{
				top: 0;
				transform: rotate(-45deg);
			}
		}
	}

	@include mq(small){
		display: inline-block;
	}
}

.main-nav .mobile-main-logo {
	&:after {
		display: none;
	}
}


