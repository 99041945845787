/* =======================================================================
Responsive
========================================================================== */
/* =======================================================================
Font-face
========================================================================== */
@font-face {
  font-family: 'brandon-light';
  src: url(../fonts/Brandon_light.otf) format("opentype");
}

@font-face {
  font-family: 'brandon-medium';
  src: url(../fonts/Brandon_med.otf) format("opentype");
}

@font-face {
  font-family: 'brandon-bold';
  src: url(../fonts/Brandon_bld.otf) format("opentype");
}

@font-face {
  font-family: 'brandon-black';
  src: url(../fonts/Brandon_blk.otf) format("opentype");
}

/* =======================================================================
Variables
========================================================================== */
/* line 1, assets/src/scss/modules/_mixins.scss */
.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

/* line 7, assets/src/scss/modules/_mixins.scss */
.clearfix:after {
  clear: both;
}

/* line 11, assets/src/scss/modules/_mixins.scss */
.clearfix {
  *zoom: 1;
}

/* line 15, assets/src/scss/modules/_mixins.scss */
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 24, assets/src/scss/modules/_mixins.scss */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* line 25, assets/src/scss/modules/_mixins.scss */
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* line 39, assets/src/scss/modules/_mediaquery.scss */
#breakpoint:before {
  display: block;
  content: "xlarge (default --> 1025px to 1199px)";
}

@media (min-height: 780px) {
  /* line 38, assets/src/scss/modules/_mediaquery.scss */
  #breakpoint {
    color: green;
  }
}

@media (max-width: 1199px) {
  /* line 49, assets/src/scss/modules/_mediaquery.scss */
  #breakpoint:before {
    content: "large --> max-width: 1024px";
  }
}

@media (max-width: 767px) {
  /* line 55, assets/src/scss/modules/_mediaquery.scss */
  #breakpoint:before {
    content: "medium --> max-width: 767px";
  }
}

@media (max-width: 599px) {
  /* line 61, assets/src/scss/modules/_mediaquery.scss */
  #breakpoint:before {
    content: "small --> max-width: 599px";
  }
}

@media (max-width: 479px) {
  /* line 67, assets/src/scss/modules/_mediaquery.scss */
  #breakpoint:before {
    content: "xsmall --> max-width: 479px";
  }
}

@media (max-width: 1499px) {
  /* line 73, assets/src/scss/modules/_mediaquery.scss */
  #breakpoint:before {
    content: "xxl --> min-width: 1200px";
  }
}

/* line 1, assets/src/scss/modules/_base.scss */
html,
button,
input,
select,
textarea,
body {
  color: #5d5d5d;
  font-family: "brandon-light", "Quicksand", sans-serif;
}

/* line 11, assets/src/scss/modules/_base.scss */
body {
  background: #efefef;
  font-size: 20px;
  line-height: 1.4;
}

@media (max-width: 1199px) {
  /* line 11, assets/src/scss/modules/_base.scss */
  body {
    font-size: 16px;
  }
}

/* line 22, assets/src/scss/modules/_base.scss */
b, strong {
  font-family: "brandon-black", "Quicksand", sans-serif;
}

/* line 26, assets/src/scss/modules/_base.scss */
a {
  color: #26509a;
  text-decoration: none;
  transition: 0.25s all ease;
}

/* line 33, assets/src/scss/modules/_base.scss */
blockquote p {
  font-family: "brandon-black", "Quicksand", sans-serif;
  color: #26509a;
}

/* line 39, assets/src/scss/modules/_base.scss */
img {
  vertical-align: middle;
  max-width: 100%;
}

/* line 41, assets/src/scss/modules/_base.scss */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/* line 50, assets/src/scss/modules/_base.scss */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/* line 55, assets/src/scss/modules/_base.scss */
textarea {
  resize: vertical;
}

/* line 60, assets/src/scss/modules/_base.scss */
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #5d5d5d;
}

/* line 63, assets/src/scss/modules/_base.scss */
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #5d5d5d;
}

/* line 66, assets/src/scss/modules/_base.scss */
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #5d5d5d;
}

/* line 69, assets/src/scss/modules/_base.scss */
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #5d5d5d;
}

/* line 73, assets/src/scss/modules/_base.scss */
.copyright-bar {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
  text-align: center;
}

@media screen and (max-width: 599px) {
  /* line 73, assets/src/scss/modules/_base.scss */
  .copyright-bar {
    flex-flow: column wrap;
  }
}

/* line 85, assets/src/scss/modules/_base.scss */
.copyright-bar .social {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/* line 90, assets/src/scss/modules/_base.scss */
.copyright-bar .social i {
  margin-left: 15px;
  font-size: 15px;
  color: #322f31;
  transition: all 300ms ease;
}

@media screen and (max-width: 599px) {
  /* line 90, assets/src/scss/modules/_base.scss */
  .copyright-bar .social i {
    margin: 0px 15px;
  }
}

/* line 100, assets/src/scss/modules/_base.scss */
.copyright-bar .social i:hover {
  color: #276fed;
}

/* line 1, assets/src/scss/modules/_nav.scss */
.main-nav {
  text-align: center;
  position: relative;
}

/* line 5, assets/src/scss/modules/_nav.scss */
.main-nav ul {
  margin: 30px 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}

@media (max-width: 1199px) {
  /* line 5, assets/src/scss/modules/_nav.scss */
  .main-nav ul {
    margin-top: 0;
  }
}

@media (max-width: 599px) {
  /* line 5, assets/src/scss/modules/_nav.scss */
  .main-nav ul {
    max-height: 0;
    pointer-events: none;
    overflow: hidden;
    transition: 0.4s max-height ease;
  }
  /* line 20, assets/src/scss/modules/_nav.scss */
  .mobile-on .main-nav ul {
    pointer-events: auto;
    max-height: 1000px;
    transition: 0.4s max-height cubic-bezier(1, 0, 1, 0.57);
  }
}

/* line 28, assets/src/scss/modules/_nav.scss */
.main-nav li {
  display: inline-block;
  vertical-align: middle;
}

/* line 33, assets/src/scss/modules/_nav.scss */
.main-nav a {
  color: #323132;
  font-family: "brandon-black", "Quicksand", sans-serif;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 20px;
  padding: 0 10px;
}

/* line 43, assets/src/scss/modules/_nav.scss */
.main-nav a:after {
  content: '';
  display: block;
  height: 1px;
  width: 0px;
  margin: 5px 0 0 0;
  background: #26509a;
  transition: 0.25s all ease;
}

/* line 53, assets/src/scss/modules/_nav.scss */
.main-nav a:hover, .main-nav a:focus {
  color: #26509a;
  text-decoration: none;
}

/* line 58, assets/src/scss/modules/_nav.scss */
.main-nav a:hover:after, .main-nav a:focus:after {
  margin: 5px 0 0 0;
  width: 100%;
}

/* line 66, assets/src/scss/modules/_nav.scss */
.main-nav .main-logo a {
  width: 250px;
  display: inline-block;
}

/* line 70, assets/src/scss/modules/_nav.scss */
.main-nav .main-logo a:after {
  display: none;
}

@media (max-width: 1199px) {
  /* line 65, assets/src/scss/modules/_nav.scss */
  .main-nav .main-logo {
    display: none;
  }
}

/* line 80, assets/src/scss/modules/_nav.scss */
.main-nav .mobile-main-logo {
  display: none;
  width: 180px;
  margin: 30px auto 10px auto;
}

@media (max-width: 1199px) {
  /* line 80, assets/src/scss/modules/_nav.scss */
  .main-nav .mobile-main-logo {
    display: block;
  }
}

/* line 90, assets/src/scss/modules/_nav.scss */
.main-nav .right-menu {
  margin: 0 0 0 100px;
}

/* line 93, assets/src/scss/modules/_nav.scss */
.main-nav .right-menu a {
  margin: 0 10px;
  padding: 0;
}

/* line 97, assets/src/scss/modules/_nav.scss */
.main-nav .right-menu a:after {
  display: none;
}

@media (max-width: 1199px) {
  /* line 90, assets/src/scss/modules/_nav.scss */
  .main-nav .right-menu {
    margin: 0 0 0 30px;
  }
}

@media (max-width: 599px) {
  /* line 90, assets/src/scss/modules/_nav.scss */
  .main-nav .right-menu {
    margin: 0;
  }
  /* line 109, assets/src/scss/modules/_nav.scss */
  .main-nav .right-menu a {
    width: auto;
  }
}

@media (max-width: 1199px) {
  /* line 116, assets/src/scss/modules/_nav.scss */
  .main-nav a {
    margin: 0 10px;
  }
}

@media (max-width: 599px) {
  /* line 122, assets/src/scss/modules/_nav.scss */
  .main-nav li {
    display: block;
  }
  /* line 125, assets/src/scss/modules/_nav.scss */
  .main-nav a {
    margin: 0;
    padding: 10px 0;
    width: 100%;
  }
}

/* line 133, assets/src/scss/modules/_nav.scss */
.menu-left-part-container,
.menu-right-part-container {
  display: inline-block;
}

/* line 139, assets/src/scss/modules/_nav.scss */
.facebook-page svg {
  width: 20px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
}

/* line 146, assets/src/scss/modules/_nav.scss */
.facebook-page:hover, .facebook-page:focus {
  fill: #26509a;
}

/* line 152, assets/src/scss/modules/_nav.scss */
.lang-toggle {
  position: relative;
  display: inline-block;
  top: 3px;
}

/* line 158, assets/src/scss/modules/_nav.scss */
.svg-sprite {
  position: absolute;
  pointer-events: none;
}

/* line 163, assets/src/scss/modules/_nav.scss */
.mobile-menu-toggle {
  position: absolute;
  right: 15px;
  top: 20px;
  display: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px 35px 16px 0px;
  transition: top 0.4s, transform 0.3s;
  z-index: 15;
  outline: none;
}

/* line 176, assets/src/scss/modules/_nav.scss */
.mobile-menu-toggle span, .mobile-menu-toggle span:before, .mobile-menu-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 30px;
  background: #5d5d5d;
  position: absolute;
  display: block;
  content: '';
  transition: all 0s;
}

/* line 188, assets/src/scss/modules/_nav.scss */
.mobile-menu-toggle span:before {
  top: -10px;
}

/* line 191, assets/src/scss/modules/_nav.scss */
.mobile-menu-toggle span:after {
  bottom: -10px;
}

/* line 195, assets/src/scss/modules/_nav.scss */
.mobile-on .mobile-menu-toggle span {
  background: transparent;
}

/* line 197, assets/src/scss/modules/_nav.scss */
.mobile-on .mobile-menu-toggle span:before {
  top: 0;
  transform: rotate(45deg);
}

/* line 201, assets/src/scss/modules/_nav.scss */
.mobile-on .mobile-menu-toggle span:after {
  top: 0;
  transform: rotate(-45deg);
}

@media (max-width: 599px) {
  /* line 163, assets/src/scss/modules/_nav.scss */
  .mobile-menu-toggle {
    display: inline-block;
  }
}

/* line 214, assets/src/scss/modules/_nav.scss */
.main-nav .mobile-main-logo:after {
  display: none;
}

/* line 1, assets/src/scss/modules/_layout.scss */
.container {
  width: calc( 100% - 100px);
  margin: 0 auto;
  max-width: 1500px;
  padding: 0;
}

@media (max-width: 1499px) {
  /* line 1, assets/src/scss/modules/_layout.scss */
  .container {
    margin: 0 50px;
  }
}

@media (max-width: 767px) {
  /* line 1, assets/src/scss/modules/_layout.scss */
  .container {
    margin: 0 12px;
    width: calc( 100% - 24px);
  }
}

/* line 17, assets/src/scss/modules/_layout.scss */
.container-text {
  overflow: hidden;
  clear: both;
  padding: 60px;
}

@media (max-width: 767px) {
  /* line 17, assets/src/scss/modules/_layout.scss */
  .container-text {
    padding: 40px 20px;
  }
}

/* line 31, assets/src/scss/modules/_layout.scss */
.bg-white {
  background: #fff;
}

/* line 35, assets/src/scss/modules/_layout.scss */
footer {
  padding: 40px 0;
}

/* line 38, assets/src/scss/modules/_layout.scss */
footer .inline-block {
  margin: 0 20px;
  color: #322f31;
  font-size: 15px;
  font-family: "brandon-black", "Quicksand", sans-serif;
}

/* line 46, assets/src/scss/modules/_layout.scss */
.footer-logo {
  width: 45px;
}

@media (max-width: 599px) {
  /* line 46, assets/src/scss/modules/_layout.scss */
  .footer-logo {
    display: block;
    margin: 10px auto;
  }
}

/* line 55, assets/src/scss/modules/_layout.scss */
.page-banner {
  width: 100%;
  min-height: 600px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 120px;
  padding: 0 12px;
}

/* line 69, assets/src/scss/modules/_layout.scss */
.page-banner.with-overlay:before {
  content: " ";
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.35);
  z-index: 0;
  position: absolute;
}

/* line 82, assets/src/scss/modules/_layout.scss */
.page-banner.with-overlay .horizontal-align {
  z-index: 1;
  position: relative;
}

/* line 88, assets/src/scss/modules/_layout.scss */
.page-banner h1 {
  max-width: 900px;
  margin: auto;
  color: #fff;
  font-size: 45px;
  font-family: "brandon-black", "Quicksand", sans-serif;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
}

/* line 98, assets/src/scss/modules/_layout.scss */
.page-banner h1:before {
  content: '';
  display: block;
  width: 60px;
  height: 6px;
  background: #26509a;
  margin: 0 auto 30px auto;
}

/* line 108, assets/src/scss/modules/_layout.scss */
.page-banner h2 {
  font-family: "brandon-black", "Quicksand", sans-serif;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  font-size: 24px;
}

@media (max-width: 1199px) {
  /* line 120, assets/src/scss/modules/_layout.scss */
  .page-banner h1 {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  /* line 55, assets/src/scss/modules/_layout.scss */
  .page-banner {
    min-height: auto;
    padding: 100px 12px;
    margin-bottom: 20px;
  }
}

/* =======================================================================
Grid
========================================================================== */
/* line 136, assets/src/scss/modules/_layout.scss */
.l-12 {
  width: 100%;
}

/* line 140, assets/src/scss/modules/_layout.scss */
.l-8 {
  width: 66.6666%;
  float: left;
}

@media (max-width: 767px) {
  /* line 140, assets/src/scss/modules/_layout.scss */
  .l-8 {
    width: 100%;
  }
}

/* line 149, assets/src/scss/modules/_layout.scss */
.l-6 {
  width: 50%;
  float: left;
}

@media (max-width: 767px) {
  /* line 149, assets/src/scss/modules/_layout.scss */
  .l-6 {
    width: 100%;
  }
}

/* line 158, assets/src/scss/modules/_layout.scss */
.l-5 {
  width: 41.66666%;
  float: left;
}

@media (max-width: 1199px) {
  /* line 158, assets/src/scss/modules/_layout.scss */
  .l-5 {
    width: 50%;
  }
}

@media (max-width: 599px) {
  /* line 158, assets/src/scss/modules/_layout.scss */
  .l-5 {
    width: 100%;
  }
}

/* line 171, assets/src/scss/modules/_layout.scss */
.l-4 {
  width: 33.33%;
  float: left;
}

@media (max-width: 1199px) {
  /* line 171, assets/src/scss/modules/_layout.scss */
  .l-4 {
    width: 50%;
  }
}

@media (max-width: 599px) {
  /* line 171, assets/src/scss/modules/_layout.scss */
  .l-4 {
    width: 100%;
  }
}

/* line 184, assets/src/scss/modules/_layout.scss */
.l-35 {
  width: 29.16666%;
  float: left;
}

@media (max-width: 1199px) {
  /* line 184, assets/src/scss/modules/_layout.scss */
  .l-35 {
    width: 50%;
  }
}

@media (max-width: 599px) {
  /* line 184, assets/src/scss/modules/_layout.scss */
  .l-35 {
    width: 100%;
  }
}

/* line 197, assets/src/scss/modules/_layout.scss */
.l-3 {
  width: 25%;
  float: left;
}

@media (max-width: 1199px) {
  /* line 197, assets/src/scss/modules/_layout.scss */
  .l-3 {
    width: 50%;
  }
}

@media (max-width: 599px) {
  /* line 197, assets/src/scss/modules/_layout.scss */
  .l-3 {
    width: 100%;
  }
}

/* line 1, assets/src/scss/modules/_helpers.scss */
.flex {
  display: flex;
  flex-wrap: wrap;
}

/* line 6, assets/src/scss/modules/_helpers.scss */
.vertical-align {
  align-items: center;
}

/* line 10, assets/src/scss/modules/_helpers.scss */
.align-right {
  text-align: right;
}

/* line 14, assets/src/scss/modules/_helpers.scss */
.clearfix {
  overflow: hidden;
}

/* line 18, assets/src/scss/modules/_helpers.scss */
.horizontal-align {
  text-align: center;
}

/* line 22, assets/src/scss/modules/_helpers.scss */
.fully-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 28, assets/src/scss/modules/_helpers.scss */
.absolute {
  position: absolute;
}

/* line 32, assets/src/scss/modules/_helpers.scss */
.relative {
  position: relative;
}

/* line 36, assets/src/scss/modules/_helpers.scss */
.inline-block {
  display: inline-block;
}

/* line 40, assets/src/scss/modules/_helpers.scss */
.text-white {
  color: #fff;
}

/* line 44, assets/src/scss/modules/_helpers.scss */
.border-bottom {
  border-bottom: 1px solid #000;
}

/* line 48, assets/src/scss/modules/_helpers.scss */
.box-left {
  padding: 60px 60px 60px 110px;
}

@media (max-width: 1199px) {
  /* line 48, assets/src/scss/modules/_helpers.scss */
  .box-left {
    padding: 30px;
  }
}

/* line 56, assets/src/scss/modules/_helpers.scss */
.box-left--mini {
  padding: 0 0 0 40px;
}

@media (max-width: 767px) {
  /* line 56, assets/src/scss/modules/_helpers.scss */
  .box-left--mini {
    padding: 30px;
  }
}

/* line 64, assets/src/scss/modules/_helpers.scss */
.padded-block {
  padding: 0 40px;
}

@media (max-width: 767px) {
  /* line 64, assets/src/scss/modules/_helpers.scss */
  .padded-block {
    padding: 0;
  }
}

/* line 72, assets/src/scss/modules/_helpers.scss */
.white-bg {
  background: #fff;
}

/* line 76, assets/src/scss/modules/_helpers.scss */
.stick-right {
  position: absolute;
  right: -20px;
}

@media (max-width: 767px) {
  /* line 76, assets/src/scss/modules/_helpers.scss */
  .stick-right {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 599px) {
  /* line 76, assets/src/scss/modules/_helpers.scss */
  .stick-right {
    position: static;
    transform: none;
  }
}

/* line 92, assets/src/scss/modules/_helpers.scss */
.cta {
  padding: 30px 0;
  background: url(../images/gradient.jpg) no-repeat center;
  background-size: cover;
}

/* line 98, assets/src/scss/modules/_helpers.scss */
.short-banner-text {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

/* line 105, assets/src/scss/modules/_helpers.scss */
.bottom-left-logo:before {
  content: '';
  width: 50%;
  padding-top: 24%;
  background: url(../images/slideshow-bg.png) no-repeat;
  background-size: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
}

@media (max-width: 767px) {
  /* line 118, assets/src/scss/modules/_helpers.scss */
  .bottom-left-logo:before {
    display: none;
  }
}

/* line 124, assets/src/scss/modules/_helpers.scss */
.bottom-margin {
  margin-bottom: 70px;
}

/* line 128, assets/src/scss/modules/_helpers.scss */
.google-map {
  height: 450px;
}

/* line 132, assets/src/scss/modules/_helpers.scss */
.spacing-left {
  margin-left: 40px;
}

/* line 136, assets/src/scss/modules/_helpers.scss */
.grey-text {
  color: #a0a0a0;
}

/* line 1, assets/src/scss/modules/_buttons.scss */
.b-large-arrow {
  color: #fff;
  font-family: "brandon-black", "Quicksand", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  min-height: 100px;
  display: flex;
  align-items: center;
}

/* line 11, assets/src/scss/modules/_buttons.scss */
.b-large-arrow:after {
  content: '';
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-left: 45px;
  border-radius: 50%;
  border: 3px solid #276fed;
  background: url(../images/icons/arrow-right.png) no-repeat;
  background-size: 36px;
  background-position: center;
  transition: 0.25s all ease;
}

/* line 25, assets/src/scss/modules/_buttons.scss */
.b-large-arrow:hover, .b-large-arrow:focus {
  color: #fff;
  text-decoration: none;
}

/* line 30, assets/src/scss/modules/_buttons.scss */
.b-large-arrow:hover:after, .b-large-arrow:focus:after {
  background-color: #000;
  background-position: 35px;
}

@media (max-width: 599px) {
  /* line 37, assets/src/scss/modules/_buttons.scss */
  .b-large-arrow:after {
    width: 70px;
    height: 70px;
    margin-left: 20px;
  }
}

/* line 45, assets/src/scss/modules/_buttons.scss */
.button, .button--white {
  color: #2853a5;
  border: 1px solid #2853a5;
  font-size: 15px;
  font-family: "brandon-black", "Quicksand", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 15px 30px;
  display: inline-block;
}

/* line 55, assets/src/scss/modules/_buttons.scss */
.button.lighter-b, .lighter-b.button--white {
  color: #276fed;
  border-color: #276fed;
}

/* line 60, assets/src/scss/modules/_buttons.scss */
.button:hover, .button--white:hover, .button:focus, .button--white:focus {
  background: #2853a5;
  color: #fff;
  text-decoration: none;
}

/* line 66, assets/src/scss/modules/_buttons.scss */
.button:hover.lighter-b, .button--white:hover.lighter-b, .button:focus.lighter-b, .button--white:focus.lighter-b {
  background: #276fed;
}

/* line 72, assets/src/scss/modules/_buttons.scss */
.button--white {
  color: #fff;
  border: 1px solid #fff;
}

/* line 77, assets/src/scss/modules/_buttons.scss */
.button--white:hover, .button--white:focus {
  background: #fff;
  color: #26509a;
}

/* line 1, assets/src/scss/modules/_fonts.scss */
.title-underlined, .title-underlined--white {
  color: #322f31;
  font-size: 12px;
  font-family: "brandon-black", "Quicksand", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* line 8, assets/src/scss/modules/_fonts.scss */
.title-underlined:after, .title-underlined--white:after {
  content: '';
  display: block;
  height: 6px;
  width: 60px;
  margin: 10px 0 0 0;
  background: #26509a;
}

/* line 18, assets/src/scss/modules/_fonts.scss */
.title-underlined.centered:after, .centered.title-underlined--white:after {
  margin: 10px auto 0 auto;
}

/* line 24, assets/src/scss/modules/_fonts.scss */
.title-underlined--white {
  color: #fff;
}

/* line 29, assets/src/scss/modules/_fonts.scss */
.title-upper-bold, .title-upper-bold--white, #who-we-are h3 {
  color: #322f31;
  font-family: "brandon-black", "Quicksand", sans-serif;
  font-size: 45px;
  line-height: 50px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media (max-width: 1199px) {
  /* line 29, assets/src/scss/modules/_fonts.scss */
  .title-upper-bold, .title-upper-bold--white, #who-we-are h3 {
    font-size: 30px;
    line-height: 38px;
  }
}

/* line 43, assets/src/scss/modules/_fonts.scss */
.title-upper-bold--white {
  color: #fff;
}

/* line 48, assets/src/scss/modules/_fonts.scss */
.short-title {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

/* line 54, assets/src/scss/modules/_fonts.scss */
.short-paragraph {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

/* line 60, assets/src/scss/modules/_fonts.scss */
.title-small-black {
  font-family: "brandon-black", "Quicksand", sans-serif;
  text-transform: uppercase;
  color: #000;
  font-size: 24px;
}

/* line 67, assets/src/scss/modules/_fonts.scss */
.checked-list, .container-text ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* line 72, assets/src/scss/modules/_fonts.scss */
.checked-list li, .container-text ul li {
  background: url(../images/icons/list-checkmark.png) no-repeat;
  background-size: 15px 15px;
  background-position: left top 3px;
  padding-left: 30px;
  margin: 10px 0;
}

/* Slider */
/* line 3, assets/src/scss/modules/_slick.scss */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

/* line 17, assets/src/scss/modules/_slick.scss */
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

/* line 24, assets/src/scss/modules/_slick.scss */
.slick-list:focus {
  outline: none;
}

/* line 28, assets/src/scss/modules/_slick.scss */
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

/* line 33, assets/src/scss/modules/_slick.scss */
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* line 42, assets/src/scss/modules/_slick.scss */
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

/* line 48, assets/src/scss/modules/_slick.scss */
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

/* line 54, assets/src/scss/modules/_slick.scss */
.slick-track:after {
  clear: both;
}

/* line 58, assets/src/scss/modules/_slick.scss */
.slick-loading .slick-track {
  visibility: hidden;
}

/* line 62, assets/src/scss/modules/_slick.scss */
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

/* line 66, assets/src/scss/modules/_slick.scss */
[dir="rtl"] .slick-slide {
  float: right;
}

/* line 69, assets/src/scss/modules/_slick.scss */
.slick-slide img {
  display: block;
  width: 100%;
}

/* line 73, assets/src/scss/modules/_slick.scss */
.slick-slide.slick-loading img {
  display: none;
}

/* line 79, assets/src/scss/modules/_slick.scss */
.slick-slide.dragging img {
  pointer-events: none;
}

/* line 83, assets/src/scss/modules/_slick.scss */
.slick-initialized .slick-slide {
  display: block;
}

/* line 87, assets/src/scss/modules/_slick.scss */
.slick-loading .slick-slide {
  visibility: hidden;
}

/* line 91, assets/src/scss/modules/_slick.scss */
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/* line 97, assets/src/scss/modules/_slick.scss */
.slick-arrow.slick-hidden {
  display: none;
}

/* line 101, assets/src/scss/modules/_slick.scss */
.slick-next,
.slick-prev {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #276fed;
  background: url(../images/icons/arrow-right.png) no-repeat;
  background-size: 36px;
  background-position: center;
  position: absolute;
  text-indent: -9999px;
  top: 50%;
  margin-top: -50px;
  transition: 0.25s all ease;
  z-index: 2;
  outline: none;
}

/* line 119, assets/src/scss/modules/_slick.scss */
.slick-next:hover, .slick-next:focus,
.slick-prev:hover,
.slick-prev:focus {
  background-color: #000;
}

/* line 125, assets/src/scss/modules/_slick.scss */
.slick-next {
  right: -22px;
}

/* line 129, assets/src/scss/modules/_slick.scss */
.slick-prev {
  left: -22px;
  background-image: url(../images/icons/arrow-left.png);
}

/* line 134, assets/src/scss/modules/_slick.scss */
.slick-dots {
  position: absolute;
  bottom: 50px;
  left: 50%;
  padding: 0px;
  transform: translate(-50%, 0px);
}

@media (max-width: 1199px) {
  /* line 134, assets/src/scss/modules/_slick.scss */
  .slick-dots {
    bottom: 25px;
  }
}

@media (max-width: 767px) {
  /* line 134, assets/src/scss/modules/_slick.scss */
  .slick-dots {
    bottom: 0px;
  }
}

/* line 149, assets/src/scss/modules/_slick.scss */
.slick-dots li {
  display: inline-block;
  list-style: none;
  padding: 10px;
}

/* line 155, assets/src/scss/modules/_slick.scss */
.slick-dots button {
  font-size: 0px;
  display: block;
  height: 16px;
  width: 12px;
  border-radius: 12px;
  border: 2px solid white;
  background: transparent;
}

/* line 165, assets/src/scss/modules/_slick.scss */
.slick-dots li.slick-active button {
  background: white;
}

/* line 1, assets/src/scss/modules/_forms.scss */
input[type="text"],
input[type="email"],
textarea {
  border: 2px solid #cdcdcd;
  padding: 15px 25px;
  width: 100%;
  margin-bottom: 20px;
  outline: none;
  transition: 0.2s ease all;
}

/* line 11, assets/src/scss/modules/_forms.scss */
input[type="text"]:hover, input[type="text"]:focus,
input[type="email"]:hover,
input[type="email"]:focus,
textarea:hover,
textarea:focus {
  border: 2px solid #26509a;
}

/* line 17, assets/src/scss/modules/_forms.scss */
textarea {
  min-height: 200px;
}

/* line 21, assets/src/scss/modules/_forms.scss */
button[type="submit"] {
  width: 100%;
  border: none;
  outline: none;
  background: #26509a;
  padding: 15px 0;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-family: "brandon-black", "Quicksand", sans-serif;
  font-size: 15px;
  transition: 0.2s ease all;
}

/* line 34, assets/src/scss/modules/_forms.scss */
button[type="submit"]:hover, button[type="submit"]:focus {
  background: #143a7b;
}

/* line 40, assets/src/scss/modules/_forms.scss */
div.wpcf7-validation-errors {
  border: none;
  margin: 0;
  padding: 0;
  color: red;
}

/* line 47, assets/src/scss/modules/_forms.scss */
div.wpcf7-mail-sent-ok {
  border: none;
  margin: 0;
  padding: 0;
  color: #26509a;
}

/* line 1, assets/src/scss/modules/_bootstrap.scss */
.modal-open {
  overflow: hidden;
}

/* line 4, assets/src/scss/modules/_bootstrap.scss */
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

/* line 16, assets/src/scss/modules/_bootstrap.scss */
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

/* line 25, assets/src/scss/modules/_bootstrap.scss */
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

/* line 31, assets/src/scss/modules/_bootstrap.scss */
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

/* line 35, assets/src/scss/modules/_bootstrap.scss */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

/* line 40, assets/src/scss/modules/_bootstrap.scss */
.modal-content {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #999999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: 0;
  max-width: 900px;
  margin: 40px auto;
}

/* line 54, assets/src/scss/modules/_bootstrap.scss */
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}

/* line 63, assets/src/scss/modules/_bootstrap.scss */
.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}

/* line 67, assets/src/scss/modules/_bootstrap.scss */
.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

/* line 71, assets/src/scss/modules/_bootstrap.scss */
.modal-header {
  padding: 0px;
  border-bottom: none;
}

/* line 75, assets/src/scss/modules/_bootstrap.scss */
.modal-header .close {
  margin-top: 0px;
  background: #000;
  color: #eee;
  border: none;
  display: block;
  height: 50px;
  width: 50px;
  font-size: 30px;
  float: right;
}

/* line 86, assets/src/scss/modules/_bootstrap.scss */
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

/* line 90, assets/src/scss/modules/_bootstrap.scss */
.modal-body {
  position: relative;
  padding: 15px;
  text-align: center;
}

/* line 99, assets/src/scss/modules/_bootstrap.scss */
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

/* line 104, assets/src/scss/modules/_bootstrap.scss */
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}

/* line 108, assets/src/scss/modules/_bootstrap.scss */
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

/* line 111, assets/src/scss/modules/_bootstrap.scss */
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

/* line 114, assets/src/scss/modules/_bootstrap.scss */
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  /* line 122, assets/src/scss/modules/_bootstrap.scss */
  .modal-dialog {
    width: calc(100% - 40px);
    margin: 30px auto;
  }
  /* line 126, assets/src/scss/modules/_bootstrap.scss */
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  /* line 130, assets/src/scss/modules/_bootstrap.scss */
  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  /* line 135, assets/src/scss/modules/_bootstrap.scss */
  .modal-lg {
    width: 900px;
  }
}

/* line 139, assets/src/scss/modules/_bootstrap.scss */
.clearfix:before,
.clearfix:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  content: " ";
  display: table;
}

/* line 148, assets/src/scss/modules/_bootstrap.scss */
.clearfix:after,
.modal-header:after,
.modal-footer:after {
  clear: both;
}

/* line 153, assets/src/scss/modules/_bootstrap.scss */
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* line 158, assets/src/scss/modules/_bootstrap.scss */
.pull-right {
  float: right !important;
}

/* line 161, assets/src/scss/modules/_bootstrap.scss */
.pull-left {
  float: left !important;
}

/* line 164, assets/src/scss/modules/_bootstrap.scss */
.hide {
  display: none !important;
}

/* line 167, assets/src/scss/modules/_bootstrap.scss */
.show {
  display: block !important;
}

/* line 170, assets/src/scss/modules/_bootstrap.scss */
.invisible {
  visibility: hidden;
}

/* line 173, assets/src/scss/modules/_bootstrap.scss */
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

/* line 180, assets/src/scss/modules/_bootstrap.scss */
.hidden {
  display: none !important;
}

/* line 183, assets/src/scss/modules/_bootstrap.scss */
.affix {
  position: fixed;
}

/* line 187, assets/src/scss/modules/_bootstrap.scss */
.close-btn {
  background: none;
  border: none;
  outline: none;
  font-size: 40px;
  line-height: 30px;
  display: inline-block;
  margin: 0;
  padding: 5px;
  margin: 15px 0 0 15px;
  width: 40px;
}

/* line 200, assets/src/scss/modules/_bootstrap.scss */
.js-modal-picture {
  display: none;
}

/* line 203, assets/src/scss/modules/_bootstrap.scss */
.js-modal-picture.image-visible {
  display: inline-block;
}

/* line 1, assets/src/scss/pages/_accueil.scss */
.bg-qui-sommes-nous {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 767px) {
  /* line 1, assets/src/scss/pages/_accueil.scss */
  .bg-qui-sommes-nous {
    min-height: 200px;
    padding: 40px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* line 15, assets/src/scss/pages/_accueil.scss */
.grey-tile, .grey-tile--button {
  background: #322f31;
  padding: 60px 20px;
}

/* line 19, assets/src/scss/pages/_accueil.scss */
.grey-tile h3, .grey-tile--button h3 {
  margin-top: 20px;
  margin-bottom: 0;
}

/* line 25, assets/src/scss/pages/_accueil.scss */
.grey-tile__link {
  font-family: "brandon-black", "Quicksand", sans-serif;
  font-size: 36px;
  color: #fff;
  text-transform: uppercase;
  margin-left: calc( 50% - 130px);
  display: block;
  position: relative;
}

/* line 34, assets/src/scss/pages/_accueil.scss */
.grey-tile__link div {
  position: relative;
  z-index: 2;
}

/* line 39, assets/src/scss/pages/_accueil.scss */
.grey-tile__link .top-part {
  position: relative;
  z-index: 2;
}

/* line 43, assets/src/scss/pages/_accueil.scss */
.grey-tile__link .top-part:after {
  content: '';
  display: inline-block;
  width: 36px;
  height: 20px;
  background: url(../images/icons/arrow-right.png) no-repeat;
  background-size: 36px;
  opacity: 0;
  transform: translateX(0px);
  transition: 0.25s ease all;
}

/* line 56, assets/src/scss/pages/_accueil.scss */
.grey-tile__link .bottom-part {
  display: block;
  color: #a0a0a0;
  font-family: "brandon-light", "Quicksand", sans-serif;
  text-transform: none;
  font-size: 20px;
  margin-left: 20px;
  position: relative;
  z-index: 2;
}

/* line 67, assets/src/scss/pages/_accueil.scss */
.grey-tile__link:before {
  content: '';
  display: inline-block;
  width: 130px;
  height: 130px;
  margin-right: 0px;
  border-radius: 50%;
  border: 3px solid #276fed;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.25s all ease;
  position: absolute;
  left: -110px;
  top: -45px;
  z-index: 1;
}

@media (max-width: 1199px) {
  /* line 25, assets/src/scss/pages/_accueil.scss */
  .grey-tile__link {
    font-size: 24px;
    line-height: 32px;
    margin-left: calc( 50% - 50px);
  }
}

@media (max-width: 599px) {
  /* line 25, assets/src/scss/pages/_accueil.scss */
  .grey-tile__link {
    margin-left: 100px;
  }
  /* line 93, assets/src/scss/pages/_accueil.scss */
  .grey-tile__link:before {
    left: -110px;
    transform: scale(0.5);
  }
  /* line 99, assets/src/scss/pages/_accueil.scss */
  .grey-tile__link .top-part:after {
    display: none;
  }
}

/* line 106, assets/src/scss/pages/_accueil.scss */
.grey-tile--button {
  display: block;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  padding: 100px 20px 60px 20px;
}

/* line 113, assets/src/scss/pages/_accueil.scss */
.grey-tile--button:hover, .grey-tile--button:focus {
  text-decoration: none;
  background: #000;
}

/* line 118, assets/src/scss/pages/_accueil.scss */
.grey-tile--button:hover .top-part:after, .grey-tile--button:focus .top-part:after {
  opacity: 1;
  transform: translateX(40px);
}

/* line 124, assets/src/scss/pages/_accueil.scss */
.grey-tile--button:odd {
  border-right: none;
}

/* line 130, assets/src/scss/pages/_accueil.scss */
.i-bishop:before {
  background-image: url(../images/icons/bishop.png);
  background-size: 41px 75px;
}

/* line 137, assets/src/scss/pages/_accueil.scss */
.i-head:before {
  background-image: url(../images/icons/head.png);
  background-size: 61px 82px;
}

/* line 144, assets/src/scss/pages/_accueil.scss */
.i-brain:before {
  background-image: url(../images/icons/brain.png);
  background-size: 63px 71px;
}

/* line 151, assets/src/scss/pages/_accueil.scss */
.i-sound:before {
  background-image: url(../images/icons/sound.png);
  background-size: 72px 70px;
}

/* line 157, assets/src/scss/pages/_accueil.scss */
.project-tiles, .project-tiles--large {
  background-size: cover;
  background-position: center center;
  padding-top: 25%;
  position: relative;
}

/* line 167, assets/src/scss/pages/_accueil.scss */
.project-tiles:hover .project-tiles__content, .project-tiles--large:hover .project-tiles__content, .project-tiles:focus .project-tiles__content, .project-tiles--large:focus .project-tiles__content {
  opacity: 1;
  pointer-events: auto;
}

/* line 171, assets/src/scss/pages/_accueil.scss */
.project-tiles:hover .project-tiles__content span:after, .project-tiles--large:hover .project-tiles__content span:after, .project-tiles:focus .project-tiles__content span:after, .project-tiles--large:focus .project-tiles__content span:after {
  opacity: 1;
  transform: translateX(0px);
}

@media (max-width: 1199px) {
  /* line 157, assets/src/scss/pages/_accueil.scss */
  .project-tiles, .project-tiles--large {
    padding-top: 50%;
  }
}

/* line 183, assets/src/scss/pages/_accueil.scss */
.project-tiles--large {
  padding-top: 35%;
}

@media (max-width: 1199px) {
  /* line 183, assets/src/scss/pages/_accueil.scss */
  .project-tiles--large {
    padding-top: 50%;
  }
}

/* line 192, assets/src/scss/pages/_accueil.scss */
.project-tiles__content {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(38, 80, 154, 0.75);
  opacity: 0;
  pointer-events: none;
  transition: 0.25s all ease;
}

/* line 203, assets/src/scss/pages/_accueil.scss */
.project-tiles__content span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  font-family: "brandon-black", "Quicksand", sans-serif;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

/* line 214, assets/src/scss/pages/_accueil.scss */
.project-tiles__content span:after {
  content: '';
  display: block;
  margin: 10px auto;
  width: 36px;
  height: 20px;
  background: url(../images/icons/arrow-right-white.png) no-repeat;
  background-size: 100%;
  opacity: 0;
  transform: translateX(-40px);
  transition: 0.25s ease all;
  transition-delay: 0.1s;
}

/* line 230, assets/src/scss/pages/_accueil.scss */
.single-slide {
  position: relative;
}

/* line 234, assets/src/scss/pages/_accueil.scss */
.single-slide__text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* line 240, assets/src/scss/pages/_accueil.scss */
.single-slide__text .number {
  display: block;
  position: absolute;
  top: -50px;
  left: -50px;
  border: 3px solid #276fed;
  font-family: "brandon-light", "Quicksand", sans-serif;
  font-size: 60px;
  line-height: 70px;
  padding: 20px;
  border-radius: 50%;
  color: #276fed;
  z-index: 1;
  width: 115px;
  height: 115px;
  text-align: center;
  opacity: 0;
  transform: translate(-100px, 0);
  transition: 0.25s all ease;
}

/* line 260, assets/src/scss/pages/_accueil.scss */
.slide-up .single-slide__text .number {
  opacity: 1;
  transform: translate(0px, 0);
}

@media (max-width: 1199px) {
  /* line 240, assets/src/scss/pages/_accueil.scss */
  .single-slide__text .number {
    top: -25px;
    left: -45px;
    font-size: 35px;
    line-height: 40px;
    padding: 12px;
    border-radius: 50%;
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 599px) {
  /* line 240, assets/src/scss/pages/_accueil.scss */
  .single-slide__text .number {
    display: none;
  }
}

/* line 281, assets/src/scss/pages/_accueil.scss */
.single-slide__text .main {
  color: #fff;
  font-size: 110px;
  font-family: "brandon-black", "Quicksand", sans-serif;
  text-transform: uppercase;
  display: block;
  position: relative;
  z-index: 2;
  opacity: 0;
  transform: translate(-100px, 0);
  transition: 0.25s all ease;
  transition-delay: 0.1s;
}

/* line 294, assets/src/scss/pages/_accueil.scss */
.single-slide__text .main .arrow-banner {
  content: '';
  display: inline-block;
  vertical-align: middle;
  background: none;
  border: none;
  margin-left: 50px;
  margin-top: -30px;
  width: 36px;
  height: 20px;
  background: url(../images/icons/arrow-right-white.png) no-repeat;
  background-size: 100%;
  transition: 0.25s ease all;
  transition-delay: 0.1s;
  position: absolute;
  right: -60px;
  top: calc( 50% + 20px);
}

/* line 313, assets/src/scss/pages/_accueil.scss */
.slide-up .single-slide__text .main {
  opacity: 1;
  transform: translate(0px, 0);
}

@media (max-width: 1199px) {
  /* line 281, assets/src/scss/pages/_accueil.scss */
  .single-slide__text .main {
    font-size: 70px;
  }
  /* line 320, assets/src/scss/pages/_accueil.scss */
  .single-slide__text .main .arrow-banner {
    margin-left: 20px;
  }
}

@media (max-width: 599px) {
  /* line 281, assets/src/scss/pages/_accueil.scss */
  .single-slide__text .main {
    font-size: 40px;
  }
  /* line 328, assets/src/scss/pages/_accueil.scss */
  .single-slide__text .main .arrow-banner {
    display: none;
  }
}

/* line 334, assets/src/scss/pages/_accueil.scss */
.single-slide__text .second {
  color: #fff;
  font-size: 24px;
  font-family: "brandon-light", "Quicksand", sans-serif;
  float: right;
  margin-right: 50px;
  opacity: 0;
  transform: translate(-100px, 0);
  transition: 0.25s all ease;
  transition-delay: 0.2s;
}

/* line 346, assets/src/scss/pages/_accueil.scss */
.single-slide__text .second:before {
  content: '';
  width: 60px;
  height: 6px;
  margin-bottom: 10px;
  display: block;
  background: #26509a;
}

/* line 355, assets/src/scss/pages/_accueil.scss */
.slide-up .single-slide__text .second {
  opacity: 1;
  transform: translate(0px, 0);
}

@media (max-width: 1199px) {
  /* line 334, assets/src/scss/pages/_accueil.scss */
  .single-slide__text .second {
    font-size: 18px;
  }
}

@media (max-width: 599px) {
  /* line 334, assets/src/scss/pages/_accueil.scss */
  .single-slide__text .second {
    font-size: 14px;
  }
}

/* line 371, assets/src/scss/pages/_accueil.scss */
.slideshow-button-wrapper {
  position: absolute;
  bottom: 20px;
  left: 10px;
}

/* line 377, assets/src/scss/pages/_accueil.scss */
.js-slideshow-button {
  color: #fff;
  font-size: 14px;
  font-family: "brandon-light", "Quicksand", sans-serif;
  text-transform: uppercase;
  margin: 0 10px;
  display: inline-block;
  background: #322f31;
  padding: 10px 20px;
}

/* line 387, assets/src/scss/pages/_accueil.scss */
.js-slideshow-button:hover, .js-slideshow-button:focus, .js-slideshow-button.active {
  color: #fff;
  background: #000;
  text-decoration: none;
}

/* line 396, assets/src/scss/pages/_accueil.scss */
.banner {
  margin-bottom: 120px;
}

@media (max-width: 479px) {
  /* line 396, assets/src/scss/pages/_accueil.scss */
  .banner {
    display: none;
  }
}

/* line 404, assets/src/scss/pages/_accueil.scss */
.skip-banner {
  position: absolute;
  bottom: -50px;
  left: calc( 50% - 50px);
  text-indent: -9999px;
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #276fed;
  background: url(../images/icons/arrow-right.png) no-repeat;
  background-size: 36px;
  background-position: center;
  transform: rotate(90deg);
}

/* line 419, assets/src/scss/pages/_accueil.scss */
.skip-banner:hover, .skip-banner:focus {
  background-color: #000;
  background-position: 35px center;
}

@media (max-width: 767px) {
  /* line 404, assets/src/scss/pages/_accueil.scss */
  .skip-banner {
    display: none;
  }
}

/* line 1, assets/src/scss/pages/_a-propos.scss */
.title-over-border {
  font-family: "brandon-black", "Quicksand", sans-serif;
  font-size: 45px;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 30px;
}

/* line 8, assets/src/scss/pages/_a-propos.scss */
.title-over-border:before {
  content: '';
  display: block;
  width: 60px;
  height: 6px;
  background: #26509a;
  margin: 0 0 20px 0;
}

@media (max-width: 1199px) {
  /* line 1, assets/src/scss/pages/_a-propos.scss */
  .title-over-border {
    font-size: 30px;
  }
}

/* line 22, assets/src/scss/pages/_a-propos.scss */
.title-light-blue-under-border {
  font-family: "brandon-light", "Quicksand", sans-serif;
  font-size: 36px;
  margin: 0.3em 0;
  color: #26509a;
}

/* line 28, assets/src/scss/pages/_a-propos.scss */
.title-light-blue-under-border:after {
  content: '';
  display: block;
  width: 300px;
  height: 2px;
  background: #efefef;
  margin: 30px 0 0 0;
}

@media (max-width: 1199px) {
  /* line 22, assets/src/scss/pages/_a-propos.scss */
  .title-light-blue-under-border {
    font-size: 22px;
  }
  /* line 40, assets/src/scss/pages/_a-propos.scss */
  .title-light-blue-under-border:after {
    display: none;
  }
}

/* line 46, assets/src/scss/pages/_a-propos.scss */
.title-blue-small, #description h4 {
  font-family: "brandon-light", "Quicksand", sans-serif;
  font-size: 24px;
  color: #26509a;
  text-transform: uppercase;
}

@media (max-width: 1199px) {
  /* line 46, assets/src/scss/pages/_a-propos.scss */
  .title-blue-small, #description h4 {
    font-size: 18px;
  }
}

/* line 57, assets/src/scss/pages/_a-propos.scss */
.single-employee {
  display: block;
  background-size: cover;
  background-position: center;
  font-size: 24px;
  color: #000;
  font-family: "brandon-black", "Quicksand", sans-serif;
  text-transform: uppercase;
  padding: 0px 15px 15px 0px;
}

/* line 68, assets/src/scss/pages/_a-propos.scss */
.employee-title {
  display: inline;
  padding-right: 24px;
  cursor: pointer;
  background: url(../images/icons/arrow-right.png) no-repeat scroll calc(100% - 2px) 50%/16px auto;
  transition: all 300ms;
}

/* line 75, assets/src/scss/pages/_a-propos.scss */
.employee-title:hover, .employee-title:focus {
  color: #666;
}

/* line 80, assets/src/scss/pages/_a-propos.scss */
.wrapper-employees {
  padding-top: 0px;
}

/* line 86, assets/src/scss/pages/_a-propos.scss */
.employee-description p {
  margin-top: 0px;
  font-size: 18px;
  padding-right: 80px;
}

@media (max-width: 767px) {
  /* line 86, assets/src/scss/pages/_a-propos.scss */
  .employee-description p {
    padding-right: 0px;
  }
}

/* line 96, assets/src/scss/pages/_a-propos.scss */
.employee-description.text-left {
  text-align: left;
}

@media (max-width: 767px) {
  /* line 96, assets/src/scss/pages/_a-propos.scss */
  .employee-description.text-left {
    text-align: center;
  }
}

/* line 105, assets/src/scss/pages/_a-propos.scss */
.wrapper-single-employee {
  position: relative;
  overflow: hidden;
}

/* line 109, assets/src/scss/pages/_a-propos.scss */
.wrapper-single-employee.text-left {
  text-align: left;
}

@media (max-width: 767px) {
  /* line 109, assets/src/scss/pages/_a-propos.scss */
  .wrapper-single-employee.text-left {
    text-align: center;
  }
}

/* line 117, assets/src/scss/pages/_a-propos.scss */
.wrapper-single-employee span {
  font-size: 18px;
  text-transform: none;
  letter-spacing: 1px;
  display: block;
  margin: 5px 0;
  font-family: "brandon-medium", "Quicksand", sans-serif;
  color: #5d5d5d;
}

/* line 127, assets/src/scss/pages/_a-propos.scss */
.wrapper-single-employee a {
  color: #26509a;
}

/* line 130, assets/src/scss/pages/_a-propos.scss */
.wrapper-single-employee a:hover, .wrapper-single-employee a:focus {
  text-decoration: underline;
}

/* line 136, assets/src/scss/pages/_a-propos.scss */
.wrapper-single-employee a.linked-in {
  font-size: 24px;
}

@media (max-width: 1199px) {
  /* line 142, assets/src/scss/pages/_a-propos.scss */
  .full-on-tablet {
    width: 100%;
  }
}

/* line 148, assets/src/scss/pages/_a-propos.scss */
.single-combinaison {
  max-width: 150px;
  margin: 20px 40px 0 40px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

/* line 155, assets/src/scss/pages/_a-propos.scss */
.single-combinaison .number {
  font-size: 28px;
  color: #276fed;
  padding: 5px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #276fed;
  display: inline-block;
}

/* line 166, assets/src/scss/pages/_a-propos.scss */
.single-combinaison:after {
  content: '';
  background: url(../images/icons/arrow-right.png) no-repeat;
  background-size: 36px;
  width: 36px;
  height: 22px;
  display: inline-block;
  margin-left: 15px;
  position: absolute;
  top: 40px;
  right: -60px;
}

/* line 180, assets/src/scss/pages/_a-propos.scss */
.single-combinaison:last-of-type:after {
  display: none;
}

@media (max-width: 767px) {
  /* line 148, assets/src/scss/pages/_a-propos.scss */
  .single-combinaison {
    width: 100%;
  }
  /* line 188, assets/src/scss/pages/_a-propos.scss */
  .single-combinaison:after {
    display: none;
  }
}

/* line 194, assets/src/scss/pages/_a-propos.scss */
.coordinates-block {
  padding-top: 120px;
}

/* line 197, assets/src/scss/pages/_a-propos.scss */
.coordinates-block a {
  font-family: "brandon-black", "Quicksand", sans-serif;
}

@media (max-width: 767px) {
  /* line 194, assets/src/scss/pages/_a-propos.scss */
  .coordinates-block {
    padding-top: 0px;
  }
}

@media (max-width: 1199px) {
  /* line 4, assets/src/scss/pages/_services.scss */
  .realisations-block .l-4, .realisations-block .l-8 {
    width: 100%;
  }
}

/* line 12, assets/src/scss/pages/_services.scss */
.mega-icon h2 {
  font-size: 30px;
  font-family: "brandon-black", "Quicksand", sans-serif;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
}

/* line 20, assets/src/scss/pages/_services.scss */
.mega-icon p {
  font-size: 26px;
  margin: 0;
}

@media (max-width: 1199px) {
  /* line 27, assets/src/scss/pages/_services.scss */
  .mega-icon h2 {
    font-size: 24px;
  }
  /* line 31, assets/src/scss/pages/_services.scss */
  .mega-icon p {
    font-size: 18px;
  }
}

/* line 37, assets/src/scss/pages/_services.scss */
.mega-icon {
  position: relative;
  display: flex;
  align-items: center;
}

/* line 42, assets/src/scss/pages/_services.scss */
.mega-icon:before {
  content: '';
  display: block;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #26509a;
  border-radius: 50%;
  top: -45px;
  left: -50px;
  width: 140px;
  height: 140px;
}

/* line 57, assets/src/scss/pages/_services.scss */
.spacing-left {
  margin-left: 40px;
}

@media (max-width: 1199px) {
  /* line 57, assets/src/scss/pages/_services.scss */
  .spacing-left {
    margin-left: 130px;
  }
}

/* line 66, assets/src/scss/pages/_services.scss */
.mega-icon.i-bishop:before {
  background-image: url(../images/icons/bishop.png);
  background-size: 50px;
}

/* line 73, assets/src/scss/pages/_services.scss */
.mega-icon.i-head:before {
  background-image: url(../images/icons/head.png);
  background-size: 70px;
}

/* line 80, assets/src/scss/pages/_services.scss */
.mega-icon.i-brain:before {
  background-image: url(../images/icons/brain.png);
  background-size: 80px;
}

/* line 87, assets/src/scss/pages/_services.scss */
.mega-icon.i-sound:before {
  background-image: url(../images/icons/sound.png);
  background-size: 80px;
}

/* line 1, assets/src/scss/pages/_realisations.scss */
.project-title {
  font-size: 45px;
  font-family: "brandon-black", "Quicksand", sans-serif;
  color: #322f31;
  text-transform: uppercase;
  margin-bottom: 0;
  text-align: center;
}

/* line 9, assets/src/scss/pages/_realisations.scss */
.project-title:before {
  content: '';
  display: block;
  width: 60px;
  height: 6px;
  background: #26509a;
  margin: 0 auto 30px auto;
}

@media (max-width: 767px) {
  /* line 1, assets/src/scss/pages/_realisations.scss */
  .project-title {
    font-size: 28px;
  }
}

/* line 23, assets/src/scss/pages/_realisations.scss */
.project-description {
  font-size: 36px;
  color: #26509a;
  margin-top: 0;
  text-align: center;
}

@media (max-width: 767px) {
  /* line 23, assets/src/scss/pages/_realisations.scss */
  .project-description {
    font-size: 20px;
  }
}

/* line 34, assets/src/scss/pages/_realisations.scss */
.project-wrapper {
  padding: 50px 120px;
}

@media (max-width: 767px) {
  /* line 34, assets/src/scss/pages/_realisations.scss */
  .project-wrapper {
    padding: 50px 20px;
  }
}

/* line 42, assets/src/scss/pages/_realisations.scss */
.project-single-slide {
  width: 25%;
  padding-top: 7%;
  background-position: center center;
  background-size: cover;
}

/* line 48, assets/src/scss/pages/_realisations.scss */
.project-single-slide img {
  display: none;
}
