.title-underlined {
	color: $grey-black;
	font-size: 12px;
	font-family: $font-black;
	text-transform: uppercase;
	letter-spacing: 2px;

	&:after {
		content: '';
		display: block;
		height: 6px;
		width: 60px;
		margin: 10px 0 0 0;
		background: $color-link;
	}

	&.centered {
		&:after {
			margin: 10px auto 0 auto;
		}
	}
}

.title-underlined--white {
	@extend .title-underlined;
	color: #fff;
}

.title-upper-bold {
	color: $grey-black;
	font-family: $font-black;
	font-size: 45px;
	line-height: 50px;
	text-transform: uppercase;
	letter-spacing: 1px;

	@include mq(large) {
		font-size: 30px;
		line-height: 38px;
	}
}

.title-upper-bold--white {
	@extend .title-upper-bold;
	color: #fff;
}

.short-title {
	max-width: 650px;
	margin-left: auto;
	margin-right: auto;
}

.short-paragraph {
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
}

.title-small-black {
	font-family: $font-black;
	text-transform: uppercase;
	color: #000;
	font-size: 24px;
}

.checked-list {
	margin: 0;
	padding: 0;
	list-style: none;
	
	li {
		background: url(../images/icons/list-checkmark.png) no-repeat;
		background-size: 15px 15px;
		background-position: left top 3px;
		padding-left: 30px;
		margin: 10px 0;
	}
}


